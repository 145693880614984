import React, {useEffect} from "react"
import Layout from "../components/layout";
import {Row, Col, Container, Button} from "react-bootstrap";
import vipMembershipVideoStyle from "./vipMembershipVideoTemplate.module.css";
import formStyle from "../pages/form.module.css";
import VideoAudioControl from "../components/videoAudioControl";

const useStateWithLocalStorageForEmail = localStorageKey => {

  //get email value from localStorage
  const [emailValueFromLocalStorage, setEmailValueFromLocalStorage] = React.useState(
    typeof window !== "undefined" && localStorage.getItem(localStorageKey) || '',
  );

  //set user email value in localStorage
  React.useEffect(() => {
    localStorage.setItem(localStorageKey, emailValueFromLocalStorage);
  }, [emailValueFromLocalStorage]);

  return [emailValueFromLocalStorage, setEmailValueFromLocalStorage];
};

const useStateWithLocalStorageForLast4 = localStorageKey => {

  const [last4ValueFromLocalStorage, setLast4ValueFromLocalStorage] = React.useState(
    typeof window !== "undefined" && localStorage.getItem(localStorageKey) || '',
  );

  React.useEffect(() => {
    localStorage.setItem(localStorageKey, last4ValueFromLocalStorage);
  }, [last4ValueFromLocalStorage]);

  return [last4ValueFromLocalStorage, setLast4ValueFromLocalStorage];
};

export default function Template(data) {
  const [emailValueFromLocalStorage] = useStateWithLocalStorageForEmail('email');
  const [last4ValueFromLocalStorage] = useStateWithLocalStorageForLast4('last4');

  useEffect(() => {
    if (!emailValueFromLocalStorage && !last4ValueFromLocalStorage && typeof window !== "undefined") {
      window.location.replace("/become-a-member");
    }
  }, [emailValueFromLocalStorage, last4ValueFromLocalStorage]);

  const videoData = data.pageContext;

  return (
    <Layout className="site-content">
      <Container className={vipMembershipVideoStyle.vipMembershipContainer}>
        <Row>
          <Col>
            <h1 className={vipMembershipVideoStyle.videoTitle}>{videoData.name}</h1>
          </Col>
        </Row>
        <Row>
          <Col className={vipMembershipVideoStyle.videoContent}>
            <iframe id={videoData.id} src={"https://player.vimeo.com/video/" + videoData.id + "?portrait=1;byline=0;title=0;autoplay=1;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=163410"}
                    width="1000"
                    height="500"
                    frameBorder="0"
                    allow="autoplay; fullscreen"
                    allowFullScreen
                    title="{videoData.name}">
            </iframe>
            <VideoAudioControl></VideoAudioControl>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button href={'/member-lounge'} className={[formStyle.btnPrimary, formStyle.submitButtonOrange, vipMembershipVideoStyle.seeMoreButton]}>Back to Member Lounge</Button>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export const pageQuery = '';
